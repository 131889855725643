import http from './http.js'
import api from './api.js'
let API = {};

API.coursetList = function (data) { 
    return http.post(api.course,data)
}
API.courseTag = function (data) { 
    return http.post(api.courseTag,data)
}
API.commitAnswer = function (data) { 
    return http.post(api.commitAnswer,data)
}
API.registered = function (data) { 
    return http.post(api.registered,data)
}
API.userAuth = function (data) { 
    return http.post(api.userAuth,data)
}
API.inviting = function (data) { 
    return http.post(api.inviting,data)
}
API.captcha = function (data) { 
    return http.post(api.captcha,data)
}

export default API