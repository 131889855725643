import axios from 'axios';

// 环境的切换
if (process.env.NODE_ENV == 'development') {    
    axios.defaults.baseURL = '/pro';
}  
// else if (process.env.NODE_ENV == 'production') {    
//     axios.defaults.baseURL = 'http://sprout-edu.com:8000';
// }
// 请求超时时间
axios.defaults.timeout = 10000;
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// 请求拦截器
axios.interceptors.request.use(    
    config => {  
        if(localStorage.getItem('token')&&localStorage.getItem('token')!="undefined"){
            config.headers["X-Auth-Token"]=localStorage.getItem('token')
        }
        
        return config;    
    },    
    error => {        
        return Promise.error(error);    
    }
)

// 响应拦截器
axios.interceptors.response.use(    
    response => {     
        if (response.status === 200) {            
            return Promise.resolve(response);        
        } else {            
            return Promise.reject(response);        
        }    
    },
    // 服务器状态码不是200的情况    
    error => {           
    }
);
let http = {}
http.get = function(url, params){    
    return new Promise((resolve, reject) =>{     
        axios.get(url, {            
            params: params        
        })        
        .then(res => {            
            resolve(res.data);        
        })        
        .catch(err => {            
            reject(err.data)        
        })    
    });
}

http.post = function(url, params) {    
    return new Promise((resolve, reject) => {   
        axios.post(url, params)        
        .then(res => {       
            if(res){
                resolve(res.data); 
            }  
            else{
                resolve(res); 
            }       
        })        
        .catch(err => {       
            reject(err)        
        })    
    });
}
export default http