let port1 = 'https://sprout-edu.com:8000',port2 = 'https://i.sprout-edu.com';
// port1='';port2='';
let api = {
    course:port1+'/api/v1/courses',
    courseTag:port1+'/api/v1/course_tags',
    commitAnswer:port1+'/api/v1/h5/xxkt/commit_answer',
    inviting:port2+'/api/v1/passport/auth/question/award',
    registered:port2+'/api/v1/passport/account/check-phone-registered',
    userAuth:port2+'/api/v1/notify/sms/user-auth',
    captcha:port2+'/api/v1/passport/auth/by-phone-captcha'
}
export default api